import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Polls from './components/polls/Polls';
import Poll from './components/polls/Poll/Poll';
import SessionOna from './components/SessionsQna/SessionsQna';
import Home from './components/Home/Home';

function App() {
  const BaseUrl = '/events/:EVENT_ID'
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element = {<Home />} />
        <Route exact path={`${BaseUrl}/polls`} element = {<Polls />} />
        <Route exact path={`${BaseUrl}/poll/:POLLS_ID`} element = {<Poll />} />
        <Route exact path={`${BaseUrl}/sessions/:SESSION_ID/question`} element = {<SessionOna />} />
        <Route exact path={`${BaseUrl}/question`} element = {<SessionOna />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
