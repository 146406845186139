import axios from "axios";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"

const Polls = () => {
    const params = useParams(), history = useNavigate();
    const [Polls, changePolls] = React.useState({});
    const [PollsKeys, changePollsKeys] = React.useState([]);

    const Modifydata = async () => {
        const result = (await axios('https://shoutout-mock.w3api.dev/EventWeb/-MvE_6eYHqOhB1Ua2_dx')).data, temp = [];
        for (var id in result.Poll) temp.push(id);
        changePolls(result.Poll);
        changePollsKeys([...temp]);
    }
    useEffect (() => {
        Modifydata()
    }, []);
    return (
        <React.Fragment> 
        
        <div className="list-group">
            {PollsKeys.map(id => {
                return (
                <a className="list-group-item list-group-item-action" key={id}
                    onClick={() => history(`/events/:EVENT_ID/poll/${id}`)}>
                    {Polls[id].title}
                </a>);
            })}
        </div>
        </React.Fragment>
    )
}

export default Polls;