import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom"
import * as ApiService from '../../../services/api.service'

const Poll = () => {
    const params = useParams();
    const {EVENT_ID, POLLS_ID} = params;
    console.log({params})
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [poll, ChangePoll] = useState({});
    const [Questions, ChangeQuestions] = useState({});
    const [Options, ChangeOptions] = useState({});
    const [pollAnswers, ChangepollAnswers] = useState({});
    async function modifyData() {
        let result = await ApiService.GetDataWithEvent(EVENT_ID)
        let temp = [];

        console.log({result})

        for (var id in result.Poll[POLLS_ID].questions)
            temp.push(result.Poll[POLLS_ID].questions[id]);
        
        ChangePoll({ ...result.Poll[POLLS_ID], questions: [...temp] });
        
        for (let i = 0; i < temp.length; i++) {
            let options = [];
            for (var id in result.PollQuestion[temp[i]].options)
                options.push(result.PollQuestion[temp[i]].options[id]);
            result.PollQuestion[temp[i]].options = options;
        }
        ChangeQuestions(result.PollQuestion);
        console.log("result.PollQuestion", result.PollQuestion)
        ChangeOptions(result.PollOptions);

        result = await ApiService.GetEventUserData(EVENT_ID)
        ChangepollAnswers(result.PollAnswers);
    }
    useEffect(() => {
        modifyData();
        const refreshData = setInterval(()=>{
            modifyData()
        }, 5000)

        return ()=>{
            clearInterval(refreshData)
        }
    }, []);

    const nextQuestion = () => {
        if(currentQuestionIndex < poll.questions.length-1){
            setCurrentQuestionIndex(currentQuestionIndex+1)
            console.log({currentQuestionIndex})
        }else{
            setCurrentQuestionIndex(0)
        }
    }
    const prevQuestion = () => {
        if(currentQuestionIndex > 0){
            setCurrentQuestionIndex(currentQuestionIndex-1)
            console.log({currentQuestionIndex})
        }
        else{
            setCurrentQuestionIndex(poll.questions.length-1)
        }
    }

    const currentQuestion = (index) => {
        if(index >= 0){
            const id = poll.questions[index]
            window.poll = poll
            console.log('Poll Question ID:', id, index)
            if(!id) return <></>

            let helper = {}, answerlength = 0;
            for (var users in pollAnswers[id]) {
                if (helper[pollAnswers[id][users].option] == undefined)
                    helper[pollAnswers[id][users].option] = 0;
                helper[pollAnswers[id][users].option] += 1;
                answerlength += 1;
            }
            console.log('Rendering Question')
            return (
                <div key={id}>
                    <div className="card">
                        <h5 className="card-title">{Questions[id].pollQuestion}</h5>
                        <p className="card-text">
                            {/* {
                                Object.keys(pollAnswers[id]).length
                            } answers */}
                            {
                                Questions[id].options?.map((val, indx) => {
                                    let votePercent = Math.round((helper[val] * 100) / Math.max(answerlength, 1))
                                    if(isNaN(votePercent)) votePercent = 0;
                                    if(!Options[val]) {
                                        return;
                                    }
                                    return (
                                        <div key={val} className="question-options">
                                            <div>
                                                {indx+1}. &nbsp; 
                                                {Options[val].option}
                                            </div>
                                            <div style={{ marginLeft: "10px", minWidth: "95px" }} >
                                                {`${votePercent} %`}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </p>
                    </div>
                </div>
            )
        }
        return (<></>)
    }

    return (
        <React.Fragment>
            <div className="container">
                <h4 style={{fontSize: "1rem", marginTop: '10px'}}>{poll.title}</h4>
                {/* <h5>{sessions[poll.sessionId]?.name}</h5> */}

                {poll?.questions && currentQuestion(currentQuestionIndex)}
            </div>
            <div className="btn-controls">
                <div></div>
                <div>
                    <button className="btn-refresh">Question ({currentQuestionIndex+1}/{poll?.questions?.length})</button>&nbsp;
                    <img
                        onClick={()=>{modifyData()}} 
                        className="icon-refresh" 
                        src="https://ik.imagekit.io/h1rxzpffx/icons8-update-left-rotation-50_WObYM2_ZW.png?ik-sdk-version=javascript-1.4.3&updatedAt=1670281668364" 
                    />
                </div>
                <div>
                    <button className="btn-prev" onClick={prevQuestion}>Prev</button>&nbsp;
                    <button className="btn-next" onClick={nextQuestion}>Next</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Poll;