import React, { useState, useEffect } from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as ApiService from '../../services/api.service'

const NO_MODULE_SELECTED = 0;
const QNA_SELECTED = 1;
const POLLS_SELECTED = 2;

const Home = () => {
    const [selectedModule, setSelectedModule] = useState(NO_MODULE_SELECTED);
    const [isSessionSpecific, setIsSessionSpecific] = useState(false);
    const [allEvents, setAllEvents] = useState([])
    const [allEventData, setAllEventData] = useState({})
    const [selectedEvent, setSelectedEvent] = useState({})
    const [selectedPollId, setSelectedPollId] = useState(null)
    const [selectedSessionId, setSelectedSessionId] = useState(null)

    async function loadEventData() {
        let eventData = await ApiService.GetAllEventData();
        setAllEventData(eventData)
        const allEventData = Object.entries(eventData).map(([eventId, eventData]) => {
            console.log(eventData.aliasId, eventData.Poll)
            return {
                id: eventId,
                ...eventData
            }
        })
        setAllEvents(allEventData)
    }

    useEffect(() => {
        loadEventData();
    }, []);

    return (
        <div>
            <div className="container">
                <div className="form-group" >
                    <label htmlFor="exampleFormControlInput1">Search An Event</label>
                    <div className="input-group">
                        <Typeahead
                            id="basic-typeahead-single"
                            labelKey={option => `${option.title}`}
                            onChange={async (selectedOption) => {
                                if(selectedOption.length == 0) return;
                                const eventId = selectedOption[0].id
                                const [pollData, sessionData] = await Promise.all([
                                    ApiService.GetEventPolls(eventId),
                                    ApiService.GetEventSessions(eventId),
                                ])

                                const selectedEvent = {
                                    eventId,
                                    Poll: pollData,
                                    Session: sessionData,
                                };
                                setSelectedEvent(selectedEvent)
                            }}
                            options={allEvents.map((eventInfo) => ({id:eventInfo.id, title:eventInfo.title}))}
                            placeholder="Select an Event..."
                        />
                        <br/>
                    </div>
                </div>

                {selectedEvent.eventId &&

                    <div className="form-group">

                        <div className="x">
                            <div className="form-check form-check-inline">
                                <input id="moduleQna" className="form-check-input" type="radio" checked={selectedModule == QNA_SELECTED} onChange={(e) => {
                                    if (e.target.checked == true) {
                                        setSelectedModule(QNA_SELECTED);
                                    }
                                    else setSelectedModule(NO_MODULE_SELECTED);
                                }} />
                                <label htmlFor="moduleQna" className="form-check-label">Q&A</label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input 
                                id="modulePolls"
                                className="form-check-input" type="radio" checked={selectedModule == POLLS_SELECTED} onChange={(e) => {
                                    if (e.target.checked == true) {
                                        setSelectedModule(POLLS_SELECTED);
                                    }
                                    else setSelectedModule(NO_MODULE_SELECTED);
                                }} />
                                <label htmlFor="modulePolls" className="form-check-label">Polls</label>
                            </div>


                        </div>
                        <div className="x">
                            <div className="form-check form-check-inline">
                                <input id="moduleEvent" className="form-check-input" type="radio" checked={!isSessionSpecific} onChange={(e) => {
                                    if (e.target.checked == true) {
                                        setIsSessionSpecific(false);
                                    }
                                    else setIsSessionSpecific(true);
                                }} />
                                <label htmlFor="moduleEvent" className="form-check-label">Event </label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input 
                                id="moduleSession"
                                className="form-check-input" type="radio" checked={isSessionSpecific} onChange={(e) => {
                                    if (e.target.checked == true) {
                                        setIsSessionSpecific(true);
                                    }
                                    else setIsSessionSpecific(false);
                                }} />
                                <label htmlFor="moduleSession" className="form-check-label">Session</label>
                            </div>


                        </div>

                        {selectedEvent?.Poll && selectedModule == POLLS_SELECTED &&  
                            <div>
                                <br/>
                                <label htmlFor="">Select a Poll</label><br />
                                <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey={opt => opt.title}
                                    onChange={(selectedOption) => {
                                        if(selectedOption.length == 0) return;
                                        const pollId = selectedOption[0].id
                                        console.log(selectedOption)
                                        setSelectedPollId(pollId)
                                    }}
                                    options={Object.entries(selectedEvent?.Poll).map(([pollId, pollInfo]) => {
                                        return {id: pollId, title:pollInfo.title}
                                    })}
                                    placeholder="Select a Poll..."
                                />
                            </div>
                        }
                        {selectedEvent?.Session && selectedModule == QNA_SELECTED &&  isSessionSpecific &&
                            <div>
                                <br/>
                                <label htmlFor="">Select a Session</label><br />
                                <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey={opt => opt.name}
                                    onChange={(selectedOption) => {
                                        if(selectedOption.length == 0) return;
                                        const sessonId = selectedOption[0].id
                                        console.log(selectedOption)
                                        setSelectedSessionId(sessonId)
                                    }}
                                    options={Object.entries(selectedEvent?.Session).map(([sessionId, sessionInfo]) => {
                                        return {id: sessionId, ...sessionInfo}
                                    })}
                                    placeholder="Select a Session..."
                                />
                            </div>
                        }
                    </div>
                }

                <div className="form-group">
                    <button 
                    onClick={()=>{
                        let dashUrl = `/events/${selectedEvent.eventId}`
                        if(selectedModule == QNA_SELECTED){
                            if(selectedSessionId && isSessionSpecific){
                                dashUrl += `/sessions/${selectedSessionId}/question`
                            }else{
                                dashUrl += `/question`
                            }
                        }else if(selectedModule == POLLS_SELECTED){
                            dashUrl += `/poll/${selectedPollId}`
                        }
                        window.open(dashUrl)
                    }}
                    className="btn btn-primary"
                    >Open Dashboard</button>
                </div>
            </div>
        </div>
    )
}
export default Home;