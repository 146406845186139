import React, { useState, useEffect} from "react";
import * as ApiService from '../../services/api.service'
import { useParams, useNavigate } from "react-router-dom"
import _ from 'underscore'

const SessionOna = () => {
    const params = useParams();
    const {EVENT_ID, SESSION_ID} = params;
    const [questions, setQuestions] = useState([])

    async function fetchData(){
     
        const eventUserData = await ApiService.GetEventUserData(EVENT_ID)
        const eventQuestionsKeys = Object.keys(eventUserData.Questions)
        const eventQuestionsForSession = eventQuestionsKeys.map(questionId => {
            return {
                id: questionId,
                ...eventUserData.Questions[questionId]
            }
        }).filter(que=> {
            if(EVENT_ID && !SESSION_ID) return !que.sessionId
            else return que.sessionId == SESSION_ID
        })
        console.log({eventQuestionsForSession})
        setQuestions(_.sortBy(eventQuestionsForSession, 'likesCount').reverse())
    }

    useEffect(()=>{
        fetchData()

        const refreshData = setInterval(()=>{
            fetchData()
        }, 5000)

        return ()=>{
            clearInterval(refreshData)
        }
    },[])

    return (
        <div>
            {questions.map((singleQuestion,index)=>(
                <>
                <div key={index}>
                    <div className="card" style={{marginBottom:'10px'}}>
                    <h5 className="card-title">{singleQuestion.question}</h5>
                        <p className="card-text">
                            👍 {singleQuestion.likesCount}
                        </p>
                    </div>
                </div>
                </>
            ))}
        </div>
    )
}

export default SessionOna;